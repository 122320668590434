import axios from 'axios'
import { ENV_APP } from '../../../../env'
import api from '../../../api/api'

export default {
  list: async ({ id = null }) => {
    const result = api.get.listReports({ projectId: ENV_APP.ProjectID, id })
    return result
  },

  putField: async ({ id, form }) => {
    const result = api.patch.patchReport(id, form)
    return result
  },

  getFields: async ({ id, fields }) => {
    const result = api.get.getReportFields(id, fields)
    return result
  },

  updateFields: async ({ id, fields }) => {
    const result = api.patch.patchReportFields(id, fields)
    return result
  },

  getLayout: async ({ id }) => {
    const result = axios.get(`layout/${id}`)
    return result
  },

  getReport: async ({ id }) => {
    const result = axios.get(`report/fields/${id}`)
    return result
  },
}
